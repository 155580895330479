import { CButton } from '@coreui/react';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line
import ApiClient, { AppMarkerPaginator } from '../../../api/ApiClient';
// eslint-disable-next-line
import { AppMarkerFilter } from '../../../types';
import AddAppMarkerModal from '../AddAppMarkerModal/AddAppMarkerModal';
import AppMarkerExportLabDataModal from '../AppMarkerExportLabDataModal/AppMarkerExportLabDataModal';
// eslint-disable-next-line
import AppMarkerList from '../AppMarkerList/AppMarkerList';
import AppMarkerMassUpdateModal from '../AppMarkerMassUpdateModal/AppMarkerMassUpdateModal';

const ITEMS_PER_PAGE = 25;

const AppMarkerIndex: React.FC = () => {
  const [showAddCodesModal, setShowAddCodesModal] = useState<boolean>(false);
  const [showMassUpdateModal, setShowMassUpdateModal] =
    useState<boolean>(false);
  const [showExportLabDataModal, setShowExportLabDataModal] =
    useState<boolean>(false);

  const [markerPaginator, setMarkerPaginator] = useState<AppMarkerPaginator>({
    count: 0,
    markers: [],
  });

  const synchronize = async (): Promise<void> => {
    await ApiClient.syncAppMarker();
    document.location.reload();
  };

  const handlePageChange = async (
    markerFilter: AppMarkerFilter,
  ): Promise<void> => {
    const res = await ApiClient.filterAppMarkerWithPagination(markerFilter);
    setMarkerPaginator(res);
  };

  useEffect(() => {
    const fetchMarker = async () => {
      const res = await ApiClient.getAppMarkerWithPagination(0, 25);
      setMarkerPaginator(res);
    };
    fetchMarker();
  }, []);
  return (
    <div className="">
      <div className="text-right my-4">
        <CButton
          className="mx-2"
          color="primary"
          onClick={() => setShowAddCodesModal(true)}>
          Register App Marker
        </CButton>

        <CButton
          className="mx-2"
          color="primary"
          onClick={() => setShowMassUpdateModal(true)}>
          Perform mass update
        </CButton>

        <CButton
          className="mx-2"
          color="primary"
          onClick={() => setShowExportLabDataModal(true)}>
          Export lab data
        </CButton>

        <CButton className="" color="success" onClick={synchronize}>
          Synchronize
        </CButton>
      </div>

      {showAddCodesModal && (
        <AddAppMarkerModal closeModal={() => setShowAddCodesModal(false)} />
      )}

      {showMassUpdateModal && (
        <AppMarkerMassUpdateModal
          closeModal={() => setShowMassUpdateModal(false)}
        />
      )}

      {showExportLabDataModal && (
        <AppMarkerExportLabDataModal
          closeModal={() => setShowExportLabDataModal(false)}
        />
      )}

      <AppMarkerList
        handlePageChange={handlePageChange}
        markerPaginator={markerPaginator}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    </div>
  );
};

export default AppMarkerIndex;
