import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CDataTable,
  CInput,
} from '@coreui/react';
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import ApiClientMarkerType, { MarkerType } from '../../api/ApiClientMarkerType';

const MarkerTypes: React.FC = () => {
  const [types, setTypes] = useState<MarkerType[]>([]);
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    const getTypes = async () => {
      const res = await ApiClientMarkerType.getAll();
      setTypes(res);
    };
    getTypes();
  }, []);

  const addMarkerType = async () => {
    await ApiClientMarkerType.create(title);
    document.location.reload();
  };

  const deleteMarker = async (id: number) => {
    await ApiClientMarkerType.delete(id);
    document.location.reload();
  };

  const fields = [
    { key: 'id', label: 'Type ID' },
    { key: 'title', label: 'Title' },
    { key: 'action', label: '', _style: { width: '20%' } },
  ];

  return (
    <CCard className="">
      <CCardHeader>
        <div>
          <h4>Marker Types</h4>
        </div>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          items={types}
          fields={fields}
          hover
          striped
          sorter
          border
          scopedSlots={{
            // eslint-disable-next-line react/display-name
            action: ({ id }: MarkerType) => {
              return (
                <th className="text-center">
                  <CButton color="danger" onClick={() => deleteMarker(id)}>
                    delete
                  </CButton>{' '}
                </th>
              );
            },
          }}
        />
      </CCardBody>
      <CCardFooter>
        <div className="d-flex align-items-center">
          <CInput
            className="w-25 mr-2"
            type="text"
            placeholder="Title"
            onChange={(e) => setTitle(e.currentTarget.value)}
          />
          <CButton color="success" onClick={addMarkerType}>
            Add Type
          </CButton>
        </div>
      </CCardFooter>
    </CCard>
  );
};

export default MarkerTypes;
