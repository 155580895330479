import { CButton } from '@coreui/react';
import React, { useState } from 'react';
import CodeListTable from '../CodeListTable/CodeListTable';
import CodesAddModal from '../CodesAddModal/CodesAddModal';

const CodesIndex: React.FC = () => {
  const [showAddCodesModal, setShowAddCodesModal] = useState<boolean>(false);

  return (
    <div>
      <div className="text-right my-4">
        <CButton
          className="mx-2"
          color="primary"
          onClick={() => setShowAddCodesModal(true)}>
          Generate Codes
        </CButton>
      </div>

      <CodeListTable />

      {showAddCodesModal && (
        <CodesAddModal closeModal={() => setShowAddCodesModal(false)} />
      )}
    </div>
  );
};

export default CodesIndex;
