/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CAlert } from '@coreui/react';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ApiClient from '../../../api/ApiClient';

interface Props {
  closeModal: CallableFunction;
}

const AppMarkerExportLabDataModal: React.FC<Props> = (props: Props) => {
  const { closeModal } = props;
  const [startId, setStartId] = useState<number | null>(null);
  const [endId, setEndId] = useState<number | null>(null);

  const [incomplete, setIncomplete] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onSubmit = async () => {
    if (!startId || !endId) {
      setIncomplete(true);
    } else {
      setIncomplete(false);

      try {
        const labData = await ApiClient.exportLabDataAppMarker(startId, endId);
        const element = document.createElement('a');
        const file = new Blob([labData], {
          type: 'text/csv',
        });

        element.href = URL.createObjectURL(file);
        element.download = `export-app-marker-lab-data-${startId}-${endId}.csv`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        closeModal();
      } catch (err) {
        setError(true);
      }
    }
  };

  return (
    <Modal show animation={false} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>Export app-marker lab data</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {incomplete && (
          <CAlert color="warning" size={1}>
            You have to complete all mandantory fields.
          </CAlert>
        )}

        <Form>
          <Form.Group>
            <Form.Label>Start consecutive number*</Form.Label>
            <Form.Control
              type="number"
              value={String(startId)}
              onChange={(event) => setStartId(parseInt(event.target.value, 10))}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>End consecutive number*</Form.Label>
            <Form.Control
              type="number"
              value={String(endId)}
              onChange={(event) => setEndId(parseInt(event.target.value, 10))}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {error && (
          <CAlert color="danger" size={1}>
            Something went wrong! Please change the input and retry!
          </CAlert>
        )}

        <Button size="lg" onClick={() => onSubmit()}>
          Export lab data
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppMarkerExportLabDataModal;
