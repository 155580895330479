import { CButton } from '@coreui/react';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line
import ApiClient, { PracticeMarkerPaginator } from '../../../api/ApiClient';
// eslint-disable-next-line
import { PracticeMarkerFilter } from '../../../types';
import AddPracticeMarkerModal from '../AddPracticeMarkerModal/AddPracticeMarkerModal';
import PracticeMarkerExportLabDataModal from '../PracticeMarkerExportLabDataModal/PracticeMarkerExportLabDataModal';
import PracticeMarkerImportModal from '../PracticeMarkerImportModal/PracticeMarkerImportModal';
// eslint-disable-next-line
import PracticeMarkerList from '../PracticeMarkerList/PracticeMarkerList';
import PracticeMarkerMassUpdateModal from '../PracticeMarkerMassUpdateModal/PracticeMarkerMassUpdateModal';

const ITEMS_PER_PAGE = 25;

const PracticeMarkerIndex: React.FC = () => {
  const [showAddMarkerModal, setShowAddMarkerModal] = useState<boolean>(false);
  const [showMassUpdateModal, setShowMassUpdateModal] =
    useState<boolean>(false);
  const [showExportLabDataModal, setShowExportLabDataModal] =
    useState<boolean>(false);
  const [showImportPracticeMarkersModal, setShowImportPracticeMarkersModal] =
    useState<boolean>(false);
  const [markerPaginator, setMarkerPaginator] =
    useState<PracticeMarkerPaginator>({
      count: 0,
      markers: [],
    });

  const handlePageChange = async (
    markerFilter: PracticeMarkerFilter,
  ): Promise<void> => {
    const res = await ApiClient.filterPracticeMarkerWithPagination(
      markerFilter,
    );
    setMarkerPaginator(res);
  };

  useEffect(() => {
    const fetchMarker = async () => {
      const res = await ApiClient.getPracticeMarkerWithPagination(0, 25);
      setMarkerPaginator(res);
    };
    fetchMarker();
  }, []);

  return (
    <div className="">
      <div className="text-right my-4">
        <CButton
          className="mx-2"
          color="primary"
          onClick={() => setShowAddMarkerModal(true)}>
          Register practice marker
        </CButton>

        <CButton
          className="mx-2"
          color="primary"
          onClick={() => setShowMassUpdateModal(true)}>
          Perform mass update
        </CButton>

        <CButton
          className="mx-2"
          color="primary"
          onClick={() => setShowExportLabDataModal(true)}>
          Export lab data
        </CButton>

        <CButton
          className="mx-2"
          color="primary"
          onClick={() => setShowImportPracticeMarkersModal(true)}>
          Import practice markers
        </CButton>
      </div>

      {showAddMarkerModal && (
        <AddPracticeMarkerModal
          closeModal={() => setShowAddMarkerModal(false)}
        />
      )}

      {showMassUpdateModal && (
        <PracticeMarkerMassUpdateModal
          closeModal={() => setShowMassUpdateModal(false)}
        />
      )}

      {showExportLabDataModal && (
        <PracticeMarkerExportLabDataModal
          closeModal={() => setShowExportLabDataModal(false)}
        />
      )}

      {showImportPracticeMarkersModal && (
        <PracticeMarkerImportModal
          closeModal={() => setShowImportPracticeMarkersModal(false)}
        />
      )}

      <PracticeMarkerList
        handlePageChange={handlePageChange}
        markerPaginator={markerPaginator}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    </div>
  );
};

export default PracticeMarkerIndex;
