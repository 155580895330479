import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CDataTable,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CPagination,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
// eslint-disable-next-line
import ApiClient, { PracticeMarker, PracticeMarkerPaginator } from '../../../api/ApiClient';
import ApiClientMarkerType, {
  // eslint-disable-next-line
  MarkerType,
} from '../../../api/ApiClientMarkerType';
import { PracticeMarkerFilter } from '../../../types';

import './PracticeMarkerList.scss';

interface Props {
  markerPaginator: PracticeMarkerPaginator;
  handlePageChange: (markerFilter: PracticeMarkerFilter) => Promise<void>;
  itemsPerPage: number;
}

const PracticeMarkerList: React.FC<Props> = ({
  markerPaginator,
  handlePageChange,
  itemsPerPage,
}: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({ markerTypes: [], loaded: false });
  const [filterId, setFilterId] = useState<string>('');
  const [filterMarkerId, setFilterMarkerId] = useState<string>('');
  const [filterMarkerType, setFilterMarkerType] = useState<number | null>(null);
  const [filterBatchNumber, setFilterBatchNumber] = useState<string>('');
  const [filterCustomer, setFilterCustomer] = useState<string>('');
  const [filterDeliveryDate, setFilterDeliveryDate] = useState<string>('');
  const [filterComments, setFilterComments] = useState<string>('');
  const [itemOnFocus, setItemOnFocus] = useState<number>(-1);

  // update
  const [newCustomer, setNewCustomer] = useState<string>('');
  const [newComment, setNewComment] = useState<string>('');
  const [newDeliveryDate, setNewDeliveryDate] = useState<string>('');

  useEffect(() => {
    const getMarkerTypes = async () => {
      const types = await ApiClientMarkerType.getAll();
      setData({
        markerTypes: types,
        loaded: true,
      });
    };

    getMarkerTypes();
  }, []);

  const updateMarker = async (id: number) => {
    await ApiClient.updatePracticeMarker(
      id,
      newCustomer,
      newComment,
      newDeliveryDate,
    );
  };

  function createFilter(page: number): PracticeMarkerFilter {
    const tmp: PracticeMarkerFilter = {};
    tmp.page = page;
    tmp.itemsPerPage = itemsPerPage;

    if (filterId) tmp.id = filterId;
    if (filterMarkerId) tmp.markerId = filterMarkerId;
    if (filterMarkerType) tmp.markerType = filterMarkerType;
    if (filterBatchNumber) tmp.batchNumber = filterBatchNumber;
    if (filterCustomer) tmp.customer = filterCustomer;
    if (filterDeliveryDate) tmp.deliveryDate = filterDeliveryDate;
    if (filterComments) tmp.comments = filterComments;

    return tmp;
  }

  function getItems(page: number) {
    handlePageChange(createFilter(page - 1));
  }

  function getFilteredItems() {
    setCurrentPage(1);
    handlePageChange(createFilter(0));
  }

  const fields = [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'markerId',
      label: 'Marker ID',
    },
    {
      key: 'batchNumber',
      label: 'Batch No',
    },
    {
      key: 'markerType',
      label: 'Marker Type',
    },
    { key: 'action', label: '' },
  ];

  if (!data.loaded) {
    return (
      <CCard className="">
        <div className="text-center pt-5 pb-5">
          <Spinner animation="border" />
        </div>
      </CCard>
    );
  }

  return (
    <CCard className="">
      <CCardHeader>
        <div>
          <h4>Registered Practice Markers</h4>
          <div className="filter d-flex flex-column">
            <CForm
              onSubmit={(e) => {
                e.preventDefault();
                getFilteredItems();
              }}>
              <div className="d-flex">
                <CInput
                  className="m-2"
                  id="id"
                  type="text"
                  placeholder="ID"
                  value={filterId}
                  onInput={(e) => setFilterId(e.currentTarget.value)}
                />

                <CInput
                  className="m-2"
                  id="markerId"
                  type="text"
                  placeholder="Marker ID"
                  value={filterMarkerId}
                  onInput={(e) => setFilterMarkerId(e.currentTarget.value)}
                />

                <Form.Control
                  as="select"
                  className="m-2"
                  custom
                  value={String(filterMarkerType)}
                  onChange={(event) => {
                    setFilterMarkerType(Number(event.target.value));
                  }}>
                  <option value="">Marker Type</option>
                  {data.markerTypes.map((mt: MarkerType) => (
                    <option key={mt.id} value={mt.id}>
                      {mt.title}
                    </option>
                  ))}
                </Form.Control>
              </div>

              <div className="d-flex">
                <CInput
                  className="m-2"
                  id="batchNumber"
                  type="text"
                  placeholder="Batch Number"
                  value={filterBatchNumber}
                  onInput={(e) => setFilterBatchNumber(e.currentTarget.value)}
                />

                <CInput
                  className="m-2"
                  id="customer"
                  type="text"
                  placeholder="Customer"
                  value={filterCustomer}
                  onInput={(e) => setFilterCustomer(e.currentTarget.value)}
                />

                <CInput
                  className="m-2"
                  id="deliveryDate"
                  type="date"
                  placeholder="Delivery Date"
                  value={filterDeliveryDate}
                  onInput={(e) => setFilterDeliveryDate(e.currentTarget.value)}
                />

                <CInput
                  className="m-2"
                  id="comments"
                  type="text"
                  placeholder="Comments"
                  value={filterComments}
                  onInput={(e) => setFilterComments(e.currentTarget.value)}
                />
              </div>
            </CForm>

            <p>
              <small>Use * in search as wildcard character.</small>
            </p>

            <div className="text-right">
              <CButton
                className="m-2"
                color="warning"
                variant="outline"
                onClick={() => {
                  setFilterMarkerId('');
                  setFilterMarkerType(null);
                  setFilterBatchNumber('');
                  setFilterCustomer('');
                  setFilterDeliveryDate('');
                  setFilterComments('');

                  handlePageChange({ page: 0, itemsPerPage });
                  setCurrentPage(1);
                }}>
                Clear Filter
              </CButton>
              <CButton
                className="m-2"
                color="secondary"
                variant="outline"
                onClick={() => {
                  getFilteredItems();
                }}>
                Apply Filter
              </CButton>
            </div>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          items={markerPaginator.markers}
          fields={fields}
          hover
          striped
          sorter
          border
          scopedSlots={{
            // eslint-disable-next-line react/display-name
            action: (item: PracticeMarker) => {
              return (
                <td className="text-center">
                  <CButton
                    className="mx-2"
                    color="info"
                    size="sm"
                    onClick={() => {
                      if (itemOnFocus === item.id) {
                        setItemOnFocus(-1);
                      } else {
                        setItemOnFocus(item.id);
                        setNewCustomer(item.customer || '');
                        setNewComment(item.comments || '');
                        setNewDeliveryDate(item.deliveryDate || '');
                      }
                    }}>
                    Info
                  </CButton>
                </td>
              );
            },
            // eslint-disable-next-line react/display-name
            batchNumber: ({ batchNumber }: PracticeMarker) => {
              return <td className="text-center">{batchNumber || '-'}</td>;
            },
            // eslint-disable-next-line react/display-name
            markerType: ({ markerType }: PracticeMarker) => {
              return <td className="text-center">{markerType || '-'}</td>;
            },
            // eslint-disable-next-line react/display-name
            details: ({ id }: PracticeMarker) => {
              return (
                <CCollapse show={itemOnFocus === id}>
                  <CCardBody className="row_details">
                    <CInputGroup className="mb-1">
                      <CInputGroupPrepend>
                        <CInputGroupText>Customer</CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        value={newCustomer}
                        onChange={(e) => setNewCustomer(e.currentTarget.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-1">
                      <CInputGroupPrepend>
                        <CInputGroupText>Comments</CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.currentTarget.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-1">
                      <CInputGroupPrepend>
                        <CInputGroupText>Delivery Date</CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="date"
                        value={newDeliveryDate}
                        onChange={(e) =>
                          setNewDeliveryDate(e.currentTarget.value)
                        }
                      />
                    </CInputGroup>
                    <CButton
                      color="success"
                      size="sm"
                      onClick={() => updateMarker(id)}>
                      Update Marker
                    </CButton>
                  </CCardBody>
                </CCollapse>
              );
            },
          }}
        />
        <div className="d-flex justify-content-center">
          <CPagination
            activePage={currentPage}
            pages={Math.ceil(markerPaginator.count / itemsPerPage) || 1}
            onActivePageChange={(i: number) => {
              setCurrentPage(i);
              getItems(i);
            }}
          />
        </div>
      </CCardBody>
    </CCard>
  );
};

export default PracticeMarkerList;
