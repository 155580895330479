/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CAlert } from '@coreui/react';
import React, { useState, useRef, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ApiClient from '../../../api/ApiClient';
import AppMarkerTypeDropDown from '../AppMarkerTypeDropDown/AppMarkerTypeDropDown';

interface Props {
  closeModal: CallableFunction;
}
const AddAppMarkerModal: React.FC<Props> = (props: Props) => {
  const { closeModal } = props;
  const [markerId, setMarkerId] = useState<string>('');
  const [codeA, setCodeA] = useState<string>('');
  const [codeB, setCodeB] = useState<string>('');
  const [markerType, setMarkerType] = useState<string>(
    localStorage.getItem('markerType') || '',
  );
  const [batchNumber, setBatchNumber] = useState<string>(
    localStorage.getItem('batchNumber') || '',
  );
  const [customer, setCustomer] = useState<string>(
    localStorage.getItem('customer') || '',
  );
  const [comments, setComments] = useState<string>('');

  const [incomplete, setIncomplete] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const refMarkerId = useRef<HTMLInputElement>(null);
  const refCodeA = useRef<HTMLInputElement>(null);
  const refCodeB = useRef<HTMLInputElement>(null);
  const refDD = useRef<HTMLSelectElement>(null);
  const refBatch = useRef<HTMLInputElement>(null);
  const refCustomer = useRef<HTMLInputElement>(null);
  const refComment = useRef<HTMLInputElement>(null);
  const refSubmit = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (refMarkerId.current) {
      refMarkerId.current.focus();
    }
  }, [markerId]);

  const onSubmit = async () => {
    if (!codeA || !codeB || !markerId || !markerType || !batchNumber) {
      setIncomplete(true);
    } else {
      setIncomplete(false);

      localStorage.setItem('markerType', markerType);
      localStorage.setItem('batchNumber', batchNumber);
      localStorage.setItem('customer', customer);

      try {
        await ApiClient.createAppMarker(
          codeA.replace('RMA>', ''),
          codeB.replace('RMB>', ''),
          markerId,
          markerType,
          batchNumber,
          customer,
          comments,
        );
        document.location.reload();
      } catch (err) {
        setError(true);
      }
    }
  };

  return (
    <Modal show animation={false} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create app-marker</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {incomplete && (
          <CAlert color="warning" size={1}>
            You have to complete all mandantory fields.
          </CAlert>
        )}

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (refCodeA.current) {
              refCodeA.current.focus();
            }
          }}>
          <Form.Group>
            <Form.Label>Marker ID*</Form.Label>
            <Form.Control
              ref={refMarkerId}
              type="text"
              value={markerId}
              onChange={(event) => setMarkerId(event.target.value)}
            />
          </Form.Group>
        </Form>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (refCodeB.current) {
              refCodeB.current.focus();
            }
          }}>
          <Form.Group>
            <Form.Label>Code A*</Form.Label>
            <Form.Control
              ref={refCodeA}
              type="text"
              value={codeA}
              onChange={(event) => setCodeA(event.target.value)}
            />
          </Form.Group>
        </Form>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (refDD.current) {
              refDD.current.focus();
            }
          }}>
          <Form.Group>
            <Form.Label>Code B*</Form.Label>
            <Form.Control
              ref={refCodeB}
              type="text"
              value={codeB}
              onChange={(event) => setCodeB(event.target.value)}
            />
          </Form.Group>
        </Form>

        <AppMarkerTypeDropDown
          markerType={markerType}
          setMarkerType={setMarkerType}
          ref={refDD}
          refSubmit={refSubmit}
        />

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (refDD.current) {
              // refDD.current.focus();
            }
          }}>
          <Form.Group>
            <Form.Label>Batch Number *</Form.Label>
            <Form.Control
              ref={refBatch}
              type="text"
              value={batchNumber}
              onChange={(event) => setBatchNumber(event.target.value)}
            />
          </Form.Group>
        </Form>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (refDD.current) {
              // refDD.current.focus();
            }
          }}>
          <Form.Group>
            <Form.Label>Customer</Form.Label>
            <Form.Control
              ref={refCustomer}
              type="text"
              value={customer}
              onChange={(event) => setCustomer(event.target.value)}
            />
          </Form.Group>
        </Form>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (refDD.current) {
              // refDD.current.focus();
            }
          }}>
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              ref={refComment}
              type="text"
              value={comments}
              onChange={(event) => setComments(event.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {error && (
          <CAlert color="danger" size={1}>
            Something went wrong! Please change the input and retry!
          </CAlert>
        )}

        <Button ref={refSubmit} size="lg" onClick={() => onSubmit()}>
          Marker erstellen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAppMarkerModal;
