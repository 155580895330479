import {
  CAlert,
  CButton,
  CCard,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilUser, cilLockLocked } from '@coreui/icons';
import React, { useState } from 'react';
import rumalogo from '../../assets/rumalogo.svg';
import ApiClient from '../../api/ApiClient';

import './Login.scss';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [loginInProgress, setLoginInProgress] = useState(false);

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoginInProgress(true);

    const newErrors = [];

    if (!username) {
      newErrors.push('username');
    }

    if (!password) {
      newErrors.push('password');
    }

    setErrors(newErrors);

    if (newErrors.length === 0) {
      try {
        const success = await ApiClient.login(username, password);

        if (success) {
          document.location.reload();
        }
      } catch (e) {
        newErrors.push('loginFailed');
        setErrors(newErrors);
      }

      setLoginInProgress(false);
    } else {
      setLoginInProgress(false);
    }
  };

  return (
    <CCard className="Login">
      <div className="text-center logo p-2 mb-4">
        <img src={rumalogo} alt="aa" />
      </div>

      {errors.includes('loginFailed') && (
        <CAlert color="danger">Login failed.</CAlert>
      )}

      {errors.includes('username') && (
        <CAlert id="alert" color="danger">
          Enter E-Mail
        </CAlert>
      )}

      <CInputGroup className="mb-3">
        <CInputGroupPrepend>
          <CInputGroupText>
            <CIcon icon={cilUser} />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput
          type="text"
          placeholder="E-Mail"
          autoComplete="username"
          id="username"
          value={username}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setUsername(event.target.value)
          }
        />
      </CInputGroup>
      {errors.includes('password') && (
        <CAlert id="alert" color="danger">
          Enter Password
        </CAlert>
      )}
      <CInputGroup className="mb-4">
        <CInputGroupPrepend>
          <CInputGroupText>
            <CIcon icon={cilLockLocked} />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput
          id="password"
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(event.target.value)
          }
        />
      </CInputGroup>

      <CButton
        color="primary"
        className="px-4 w-100"
        type="submit"
        disabled={loginInProgress}
        onClick={submit}
        size="lg">
        Login
      </CButton>
    </CCard>
  );
};

export default Login;
