import React from 'react';
import { CContainer } from '@coreui/react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Login from '../Login/Login';
import TokenStorage from '../../api/TokenStorage';

import './Layout.scss';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <div className="Layout">
      {TokenStorage.isAuthenticated() && <Header />}

      <CContainer>
        {!TokenStorage.isAuthenticated() && <Login />}
        {TokenStorage.isAuthenticated() && (
          <div className="content__children">{children}</div>
        )}
      </CContainer>

      <Footer />
    </div>
  );
};

export default Layout;
