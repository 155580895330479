import React, { useState } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import ApiClient from '../../../api/ApiClient';

interface Props {
  closeModal: CallableFunction;
}

const CodesAddModal: React.FC<Props> = (props: Props) => {
  const { closeModal } = props;
  const [errors, setErrors] = useState<string[]>([]);
  const [codeCount, setCodeCount] = useState<number>(0);
  const [codeType, setCodeType] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const submit = async () => {
    const newErrors = [];

    if (codeCount < 1) {
      newErrors.push('count');
    }

    if (!codeType) {
      newErrors.push('type');
    }

    setErrors(newErrors);

    if (newErrors.length === 0) {
      setInProgress(true);

      await ApiClient.addCodeList(codeCount, codeType);

      setInProgress(false);
      closeModal();
      document.location.reload();
    }
  };

  return (
    <Modal show animation={false} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>Code Generator</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {errors.includes('count') && (
          <Alert variant="danger">Please enter a number!</Alert>
        )}

        <Form.Group>
          <Form.Label>Number</Form.Label>
          <Form.Control
            type="text"
            value={String(codeCount || '')}
            onChange={(event) => setCodeCount(Number(event.target.value))}
          />
        </Form.Group>

        {errors.includes('type') && (
          <Alert variant="danger">Please enter the type!</Alert>
        )}

        <Form.Group>
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            custom
            value={codeType}
            onChange={(event) => setCodeType(event.target.value)}>
            <option value="">Choose an option ...</option>
            <option value="appMarkerId">App Marker IDs</option>
            <option value="codeA">A QR codes</option>
            <option value="codeB">B QR codes</option>
            <option value="practiceMarkerId">Practice Marker IDs</option>
          </Form.Control>
        </Form.Group>

        {inProgress && (
          <div className="text-center">
            <Spinner animation="border" />
            <p>processing request...</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={inProgress} size="lg" onClick={() => submit()}>
          Generate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CodesAddModal;
