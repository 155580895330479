import { CHeader } from '@coreui/react';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import TokenStorage from '../../api/TokenStorage';
import rumalogo from '../../assets/rumalogo.svg';

import './Header.scss';

const Header: React.FC = () => {
  return (
    <CHeader className="Header">
      <div className="d-flex align-items-center justify-content-between h-100 w-100">
        <div className="d-flex align-items-center h-100 w-auto ml-2 p-2">
          <img src={rumalogo} alt="logo" className="h-100" />
          <NavLink to="/codes" className="btn btn-primary ml-4">
            Codes
          </NavLink>
          <NavLink to="/app-marker" className="btn btn-primary ml-4">
            App Marker
          </NavLink>
          <NavLink to="/practice-marker" className="btn btn-primary ml-4">
            Practice Marker
          </NavLink>
          <NavLink to="/markertypes" className="btn btn-primary ml-4">
            Marker Types
          </NavLink>
        </div>

        <div className="mr-2">
          <Link
            to="/"
            className="btn btn-primary"
            onClick={() => {
              TokenStorage.clear();
              document.location.reload();
            }}>
            Logout
          </Link>
        </div>
      </div>
    </CHeader>
  );
};

export default Header;
