import axios from 'axios';
import TokenStorage from './TokenStorage';

export const baseUrl = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = baseUrl;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response || error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (
      error.config.url === '/login' ||
      error.config.url === '/token/refresh'
    ) {
      TokenStorage.clear();
      return Promise.reject(error);
    }

    return TokenStorage.getNewToken()
      .then((token: string) => {
        const { config } = error;
        config.headers.Authorization = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error2: Error) => {
              reject(error2);
            });
        });
      })
      .catch((error3: Error) => {
        Promise.reject(error3);
      });
  },
);

export type LoginResponse = {
  token: string;
  refresh_token: string;
};

export type CodeList = {
  id: number;
  count: number;
  type: string;
  created: number;
};

export type GetCodeListsResponse = {
  success: boolean;
  codeLists: Array<CodeList>;
};

export type GetCodesResponse = {
  success: boolean;
  codes: Array<string>;
};

export type MarkerType = {
  id: number;
  title: string;
};

export default class ApiClientMarkerType {
  static getAll = async (): Promise<MarkerType[]> => {
    const response = await axios.get<MarkerType[]>(
      '/markertype',
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;

    return data;
  };

  static delete = async (id: number): Promise<MarkerType> => {
    const response = await axios.delete<MarkerType>(
      `/markertype/${id}`,
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;

    return data;
  };

  static update = async (id: number, title: string): Promise<MarkerType> => {
    const response = await axios.put<MarkerType>(
      `/markertype/${id}`,
      {
        title,
      },
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;

    return data;
  };

  static create = async (title: string): Promise<MarkerType> => {
    const response = await axios.post<MarkerType>(
      `/markertype`,
      {
        title,
      },
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;
    return data;
  };
}
