/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CAlert } from '@coreui/react';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ApiClient, {
  // eslint-disable-next-line
  ImportPracticeMarkersResponse,
} from '../../../api/ApiClient';

interface Props {
  closeModal: CallableFunction;
}

const PracticeMarkerImportModal: React.FC<Props> = (props: Props) => {
  const { closeModal } = props;
  const [csvFile, setCsvFile] = useState<string | null>(null);
  const [result, setResult] = useState<ImportPracticeMarkersResponse | null>(
    null,
  );

  const [incomplete, setIncomplete] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onSubmit = async () => {
    if (!csvFile) {
      setIncomplete(true);
    } else {
      setError(false);
      setIncomplete(false);

      try {
        const importResult = await ApiClient.importPracticeMarkers(csvFile);

        setResult(importResult);
      } catch (err) {
        setError(true);
      }
    }
  };

  return (
    <Modal show animation={false} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>CSV import for practice-marker</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {incomplete && (
          <CAlert color="warning" size={1}>
            You have to complete all mandantory fields.
          </CAlert>
        )}

        {!result && (
          <Form>
            <Form.Group>
              <Form.Label>CSV File*</Form.Label>
              <Form.Control
                type="file"
                value=""
                onChange={(event) => {
                  const targetEvent = event.target as HTMLInputElement;

                  if (targetEvent.files && targetEvent.files[0]) {
                    const selectedCsvFile = targetEvent.files[0];
                    const reader = new FileReader();

                    reader.readAsText(selectedCsvFile);
                    reader.onload = () => {
                      setCsvFile(String(reader.result));
                    };
                    reader.onerror = () => {};
                  }
                }}
              />
            </Form.Group>
          </Form>
        )}

        {result && (
          <div>
            <p>
              Successfully imported markers: {result.successfulImports.length}
            </p>
            <p>Failed imports:</p>
            {Object.keys(result.failedImports).map((line: string) => {
              return (
                <div key={line}>
                  line {line}: {result.failedImports[line]}
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {error && (
          <CAlert color="danger" size={1}>
            Something went wrong! Please change the input and retry!
          </CAlert>
        )}

        {!result && (
          <Button size="lg" onClick={() => onSubmit()}>
            Perform import
          </Button>
        )}

        {result && (
          <Button size="lg" onClick={() => closeModal()}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PracticeMarkerImportModal;
