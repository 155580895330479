import {
  CCard,
  CCardHeader,
  CButton,
  CCardBody,
  CDataTable,
  CBadge,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
// eslint-disable-next-line
import ApiClient, { CodeList } from '../../../api/ApiClient';

const CodeListTable: React.FC = () => {
  const [state, setState] = useState<{ loading: boolean; data: CodeList[] }>({
    loading: true,
    data: [],
  });

  const fields = [
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'count',
      label: 'Count',
    },
    {
      key: 'created',
      label: 'Created On',
    },
    {
      key: 'badge',
      label: 'Type',
    },
    { key: 'action', label: '' },
  ];

  function getColor(type: string): string {
    switch (type) {
      case 'markerId':
        return 'secondary';
      case 'codeA':
        return 'warning';
      case 'codeB':
        return 'info';
      default:
        return 'primary';
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const codeLists = await ApiClient.getCodeLists();

      setState({
        loading: false,
        data: codeLists,
      });
    };

    fetchData();
  }, []);

  if (state.loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <CCard className="">
      <CCardHeader>
        <div>
          <h4>Generated Codes</h4>
        </div>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          items={state.data}
          fields={fields}
          hover
          striped
          sorter
          border
          scopedSlots={{
            // eslint-disable-next-line react/display-name
            action: (codeList: CodeList) => {
              return (
                <td className="text-center">
                  <CButton
                    type="button"
                    color="success"
                    size="sm"
                    onClick={async () => {
                      let codes = await ApiClient.getCodes(codeList.id);

                      if (codeList.type === 'codeA') {
                        codes = codes.map((code) => `RMA>${code}`);
                      } else if (codeList.type === 'codeB') {
                        codes = codes.map((code) => `RMB>${code}`);
                      }

                      const rows = [`Code ${codeList.type}`, ...codes];

                      const element = document.createElement('a');
                      const file = new Blob([rows.join('\n')], {
                        type: 'text/csv',
                      });

                      element.href = URL.createObjectURL(file);
                      element.download = `code-list-${String(codeList.id)}.csv`;
                      document.body.appendChild(element); // Required for this to work in FireFox
                      element.click();
                    }}>
                    Download
                  </CButton>
                </td>
              );
            },
            // eslint-disable-next-line react/display-name
            badge: ({ type }: CodeList) => {
              return (
                <td>
                  <CBadge color={getColor(type)}>{type}</CBadge>
                </td>
              );
            },
          }}
        />
      </CCardBody>
    </CCard>
  );
};

export default CodeListTable;
