import axios from 'axios';
// eslint-disable-next-line
import { AppMarkerFilter, PracticeMarkerFilter } from '../types';
import TokenStorage from './TokenStorage';

export const baseUrl = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = baseUrl;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response || error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (
      error.config.url === '/login' ||
      error.config.url === '/token/refresh'
    ) {
      TokenStorage.clear();
      return Promise.reject(error);
    }

    return TokenStorage.getNewToken()
      .then((token: string) => {
        const { config } = error;
        config.headers.Authorization = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error2: Error) => {
              reject(error2);
            });
        });
      })
      .catch((error3: Error) => {
        Promise.reject(error3);
      });
  },
);

export type LoginResponse = {
  token: string;
  refresh_token: string;
};

export type CodeList = {
  id: number;
  count: number;
  type: string;
  created: number;
};

export type GetCodeListsResponse = {
  success: boolean;
  codeLists: Array<CodeList>;
};

export type GetCodesResponse = {
  success: boolean;
  codes: Array<string>;
};

export type AppMarker = {
  id: number;
  codeA: string;
  codeB: string;
  markerId: string;
  markerType: string;
  batchNumber?: string;
  customer?: string;
  deliveryDate?: string;
  comments?: string;
};

export type AppMarkerPaginator = {
  count: number;
  markers: AppMarker[];
};

export type PracticeMarker = {
  id: number;
  markerId: string;
  markerType: string;
  batchNumber?: string;
  customer?: string;
  deliveryDate?: string;
  comments?: string;
};

export type PracticeMarkerPaginator = {
  count: number;
  markers: PracticeMarker[];
};

export type ImportPracticeMarkersResponse = {
  successfulImports: string[];
  failedImports: { [key: string]: string };
};

export default class ApiClient {
  static login = async (
    username: string,
    password: string,
  ): Promise<boolean> => {
    const response = await axios.post<LoginResponse>('/login', {
      username,
      password,
    });

    const { data } = response;

    TokenStorage.storeToken(data.token);
    TokenStorage.storeRefreshToken(data.refresh_token);

    return true;
  };

  static getCodeLists = async (): Promise<Array<CodeList>> => {
    const response = await axios.get<Array<CodeList>>(
      '/code-list',
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;

    return data;
  };

  static addCodeList = async (
    count: number,
    type: string,
  ): Promise<Array<string>> => {
    const response = await axios.post<Array<string>>(
      '/code-list',
      {
        count,
        type,
      },
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;
    return data;
  };

  static getCodes = async (id: number): Promise<Array<string>> => {
    const response = await axios.get<Array<string>>(
      `/code-list/${String(id)}/codes`,
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;
    return data;
  };

  static createAppMarker = async (
    codeA: string,
    codeB: string,
    markerId: string,
    markerType: string,
    batchNumber?: string,
    customer?: string,
    comments?: string,
  ): Promise<AppMarker> => {
    const response = await axios.post<AppMarker>(
      '/app-marker',
      {
        codeA,
        codeB,
        markerId,
        markerType,
        batchNumber,
        customer,
        comments,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static updateAppMarker = async (
    id: number,
    customer?: string,
    comments?: string,
    deliveryDate?: string,
  ): Promise<AppMarker> => {
    const response = await axios.post<AppMarker>(
      `/app-marker/${id}`,
      {
        customer,
        comments,
        deliveryDate,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static getAppMarker = async (): Promise<AppMarker[]> => {
    const response = await axios.get<AppMarker[]>(
      '/app-marker',
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static getAppMarkerWithPagination = async (
    page: number,
    itemsPerPage: number,
  ): Promise<AppMarkerPaginator> => {
    const response = await axios.get<AppMarkerPaginator>(
      `/app-marker/${page}/${itemsPerPage}`,
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static filterAppMarkerWithPagination = async (
    markerFilter: AppMarkerFilter,
  ): Promise<AppMarkerPaginator> => {
    const response = await axios.get<AppMarkerPaginator>(
      `/app-marker/search`,
      TokenStorage.getAuthenticationWithParamsApp(markerFilter),
    );
    const { data } = response;

    return data;
  };

  static syncAppMarker = async (): Promise<{ success: boolean }> => {
    const response = await axios.get<{ success: boolean }>(
      '/app-marker/synchronize',
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;

    return data;
  };

  static massUpdateAppMarker = async (
    startId: number,
    endId: number,
    customer?: string,
    comments?: string,
    deliveryDate?: string,
  ): Promise<AppMarker> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await axios.post<any>(
      `/app-marker/mass-update`,
      {
        startId,
        endId,
        customer,
        comments,
        deliveryDate,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static exportLabDataAppMarker = async (
    startId: number,
    endId: number,
  ): Promise<string> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await axios.post<any>(
      `/app-marker/export-lab-data`,
      {
        startId,
        endId,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data.data;
  };

  static createPracticeMarker = async (
    markerId: string,
    markerType: string,
    batchNumber?: string,
    customer?: string,
    comments?: string,
  ): Promise<PracticeMarker> => {
    const response = await axios.post<PracticeMarker>(
      '/practice-marker',
      {
        markerId,
        markerType,
        batchNumber,
        customer,
        comments,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static updatePracticeMarker = async (
    id: number,
    customer?: string,
    comments?: string,
    deliveryDate?: string,
  ): Promise<PracticeMarker> => {
    const response = await axios.post<PracticeMarker>(
      `/practice-marker/${id}`,
      {
        customer,
        comments,
        deliveryDate,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static getPracticeMarker = async (): Promise<PracticeMarker[]> => {
    const response = await axios.get<PracticeMarker[]>(
      '/practice-marker',
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static getPracticeMarkerWithPagination = async (
    page: number,
    itemsPerPage: number,
  ): Promise<PracticeMarkerPaginator> => {
    const response = await axios.get<PracticeMarkerPaginator>(
      `/practice-marker/${page}/${itemsPerPage}`,
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static filterPracticeMarkerWithPagination = async (
    markerFilter: PracticeMarkerFilter,
  ): Promise<PracticeMarkerPaginator> => {
    const response = await axios.get<PracticeMarkerPaginator>(
      `/practice-marker/search`,
      TokenStorage.getAuthenticationWithParamsPractice(markerFilter),
    );
    const { data } = response;

    return data;
  };

  static massUpdatePracticeMarker = async (
    startId: number,
    endId: number,
    customer?: string,
    comments?: string,
    deliveryDate?: string,
  ): Promise<AppMarker> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await axios.post<any>(
      `/practice-marker/mass-update`,
      {
        startId,
        endId,
        customer,
        comments,
        deliveryDate,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static exportLabDataPracticeMarker = async (
    startId: number,
    endId: number,
  ): Promise<string> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await axios.post<any>(
      `/practice-marker/export-lab-data`,
      {
        startId,
        endId,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data.data;
  };

  static importPracticeMarkers = async (
    csvFile: string,
  ): Promise<ImportPracticeMarkersResponse> => {
    const response = await axios.post<ImportPracticeMarkersResponse>(
      `/practice-marker/import`,
      {
        csvFile,
      },
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };
}
