// eslint-disable-next-line
import React, { RefObject, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ApiClientMarkerType, {
  // eslint-disable-next-line
  MarkerType,
} from '../../../api/ApiClientMarkerType';

interface Props {
  setMarkerType: (x: string) => void;
  markerType: string;
  refSubmit: RefObject<HTMLButtonElement>;
}

const AppMarkerTypeDropDown = React.forwardRef<HTMLSelectElement, Props>(
  ({ setMarkerType, markerType, refSubmit }: Props, ref) => {
    const [markerTypes, setMarkerTypes] = useState<MarkerType[]>([]);

    useEffect(() => {
      const getMarkerTypes = async () => {
        const types = await ApiClientMarkerType.getAll();
        setMarkerTypes(types);
      };
      getMarkerTypes();
    }, []);
    return (
      <Form.Group>
        <Form.Label>Marker Type *</Form.Label>
        <Form.Control
          as="select"
          custom
          value={markerType}
          ref={ref}
          onChange={(event) => {
            setMarkerType(event.target.value);
            if (refSubmit.current) {
              refSubmit.current.focus();
            }
          }}>
          <option value="">Marker Type</option>
          {markerTypes.map((mt) => (
            <option key={mt.id} value={mt.id}>
              {mt.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    );
  },
);

AppMarkerTypeDropDown.displayName = 'MarkerTypeDropDown';
export default AppMarkerTypeDropDown;
