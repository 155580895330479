import React from 'react';

import './Footer.scss';

const Footer: React.FC = () => {
  return (
    <footer className="Footer">
      V{process.env.REACT_APP_VERSION} - &copy;
      {new Date().getFullYear()} Ruma GmbH
    </footer>
  );
};

export default Footer;
