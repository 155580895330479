/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CAlert } from '@coreui/react';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ApiClient from '../../../api/ApiClient';

interface Props {
  closeModal: CallableFunction;
}

const PracticeMarkerMassUpdateModal: React.FC<Props> = (props: Props) => {
  const { closeModal } = props;
  const [startId, setStartId] = useState<number | null>(null);
  const [endId, setEndId] = useState<number | null>(null);
  const [customer, setCustomer] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [deliveryDate, setDeliveryDate] = useState<string>('');

  const [incomplete, setIncomplete] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onSubmit = async () => {
    if (!startId || !endId) {
      setIncomplete(true);
    } else {
      setIncomplete(false);

      try {
        await ApiClient.massUpdatePracticeMarker(
          startId,
          endId,
          customer,
          comments,
          deliveryDate,
        );
        document.location.reload();
      } catch (err) {
        setError(true);
      }
    }
  };

  return (
    <Modal show animation={false} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>Mass update practice-marker</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {incomplete && (
          <CAlert color="warning" size={1}>
            You have to complete all mandantory fields.
          </CAlert>
        )}

        <Form>
          <Form.Group>
            <Form.Label>Start consecutive number*</Form.Label>
            <Form.Control
              type="number"
              value={String(startId)}
              onChange={(event) => setStartId(parseInt(event.target.value, 10))}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>End consecutive number*</Form.Label>
            <Form.Control
              type="number"
              value={String(endId)}
              onChange={(event) => setEndId(parseInt(event.target.value, 10))}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Customer</Form.Label>
            <Form.Control
              type="text"
              value={customer}
              onChange={(event) => setCustomer(event.target.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              type="textarea"
              value={comments}
              onChange={(event) => setComments(event.target.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Delivery Date</Form.Label>
            <Form.Control
              type="date"
              value={deliveryDate}
              onChange={(event) => setDeliveryDate(event.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {error && (
          <CAlert color="danger" size={1}>
            Something went wrong! Please change the input and retry!
          </CAlert>
        )}

        <Button size="lg" onClick={() => onSubmit()}>
          Perform mass-update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PracticeMarkerMassUpdateModal;
