import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import CodesIndex from './components/Codes/CodesIndex/CodesIndex';
import Layout from './components/Layout/Layout';
import MarkerTypes from './components/MarkerTypes/MarkerTypes';
import AppMarkerIndex from './components/AppMarker/AppMarkerIndex/AppMarkerIndex';
import PracticeMarkerIndex from './components/PracticeMarker/PracticeMarkerIndex/PracticeMarkerIndex';

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Route path="/" exact>
              Willkommen im Ruma Management System!
            </Route>
            <Route path="/codes" exact>
              <CodesIndex />
            </Route>
            <Route path="/app-marker" exact>
              <AppMarkerIndex />
            </Route>
            <Route path="/practice-marker" exact>
              <PracticeMarkerIndex />
            </Route>
            <Route path="/markertypes" exact>
              <MarkerTypes />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
};

export default App;
