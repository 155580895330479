// eslint-disable-next-line
import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
// eslint-disable-next-line
import { AppMarkerFilter, PracticeMarkerFilter } from '../types';

export default class TokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = 'token';

  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public static async getAuthentication(): Promise<AxiosRequestConfig> {
    const tokenData = this.getTokenData();
    const now = moment().unix();

    if (tokenData.exp < now + 300) {
      const refreshToken = this.getRefreshToken();

      if (refreshToken) {
        try {
          await TokenStorage.refreshToken(refreshToken);
        } catch (e) {
          this.clear();
          document.location.href = '/';

          return {};
        }

        const tokenData2 = this.getTokenData();

        if (tokenData2.exp < now + 300) {
          this.clear();
          document.location.href = '/';

          return {};
        }
      }
    }

    return {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    };
  }

  static refreshToken = async (refreshToken: string): Promise<boolean> => {
    const response = await axios.post<TokenResponse>('/token/refresh', {
      refresh_token: refreshToken,
    });

    const { data } = response;

    TokenStorage.storeToken(data.token);
    TokenStorage.storeRefreshToken(data.refresh_token);

    return true;
  };

  public static getAuthenticationWithParamsApp(
    markerFilter: AppMarkerFilter,
  ): AxiosRequestConfig {
    return {
      headers: { Authorization: `Bearer ${this.getToken()}` },
      params: markerFilter,
    };
  }

  public static getAuthenticationWithParamsPractice(
    markerFilter: PracticeMarkerFilter,
  ): AxiosRequestConfig {
    return {
      headers: { Authorization: `Bearer ${this.getToken()}` },
      params: markerFilter,
    };
  }

  public static getNewToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/token/refresh', { refresh_token: this.getRefreshToken() })
        .then((response) => {
          this.storeToken(response.data.token);
          this.storeRefreshToken(response.data.refresh_token);

          resolve(response.data.token);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken,
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  private static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  private static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }

  public static getTokenData(): TokenData {
    const token = TokenStorage.getToken();

    if (!token) {
      throw new Error('token not found');
    }

    const decoded: TokenData = jwt_decode(token);

    return decoded;
  }
}
export type TokenResponse = {
  token: string;
  refresh_token: string;
};
export type TokenData = {
  iat: number;
  exp: number;
  id: number;
};
